.admonition {
  border-color: #555;
  border-style: solid;
  border-width: 2px;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 20px;
  row-gap: 1em;
}

.admonition.admonition--caution {
  border-color: #a0721e;
}

.admonition.admonition--danger {
  border-color: #a61f1f;
}

.admonition.admonition--info {
  border-color: #3b5dbb;
}

.admonition.admonition--tip {
  border-color: #669a52;
}

.formatted-content {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  h1, h2 {
    text-wrap: balance;
  }

  h1, h2, h3 {
    font-weight: bold;
    line-height: 1.3;
  }

  h1 {
    font-size: 3.375em;
  }

  h2 {
    font-size: 2.25em;
    margin-top: 40px;
  }

  h3 {
    font-size: 1.5em;
    margin-top: 20px;
  }

  h2 + h3 {
    margin-top: 0;
  }

  iframe {
    min-height: 400px;
  }

  ol, ul {
    pre {
      margin: 20px 0;
    }

    li {
      line-height: 1.5;
      padding: 5px 0;
    }

    p + p {
      margin-top: 5px;
    }
  }

  p {
    line-height: 1.5;

    &:empty {
      display: none;
    }
  }

  ul {
    list-style-type: " -   ";
  }
}
