@import url("./_reset.css");
@import url("./_fonts.css");
@import url("./_formatted-content.css");

:root {
  --color-aside-background: var(--color-body-background);
  --color-body-background: white;
  --color-body-font: #050505;
  --color-border: var(--color-body-font);
  --color-link-active: #ffc764;
  --color-link-decoration: var(--color-link-active);
  --color-primary-banner-background: var(--color-body-background);
  --color-primary-banner-font: var(--color-body-font);
  --color-primary-footer-background: var(--color-body-background);
  --color-primary-navigation-background: #ffc764;
  --color-text-decoration-highlight: var(--color-body-font);
  --color-tag-background: transparent;
  --color-tag-border: var(--color-body-font);
  --color-tag-font: var(--color-body-font);
  --font-family-body: "Noto Sans", sans-serif;
  --font-family-serif: "Noto Serif", Garamond, Georgia, serif;
  --font-family-monospace: "Noto Mono", monospace;
  --height-scrollbar-horizontal: 8px;
  --width-scrollbar-vertical: 8px;
  --z-index-breadcrumbs: 1;

  @media screen and (max-height: 1199px) {
    --font-size-base: 18px;
    --font-size-code: 16px;
    --font-size-smaller: 16px;
  }
  @media screen and (min-height: 1200px) {
    --font-size-base: 20px;
    --font-size-code: 18px;
    --font-size-smaller: 18px;
  }
}


::-webkit-scrollbar {
  background-color: transparent;
  height: var(--height-scrollbar-horizontal);
  width: var(--width-scrollbar-vertical);
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-border);
}

a {
  transition:
    filter 0.1s ease,
    opacity 0.1s ease;
}

a:active,
.turbo-clicked {
  filter: grayscale(0.5);
  transform: translate3d(1px, 1px, 0);
}

.turbo-clicked {
  opacity: 0.8;
  pointer-events: none;
}

.body-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.breadcrumbs,
.primary-navigation {
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  display: flex;
  flex-wrap: nowrap;
  font-size: var(--font-size-smaller);
  overflow-x: auto;
  width: 100%;
}

.breadcrumbs {
  background-color: var(--color-body-background);
  flex-direction: row-reverse;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: var(--z-index-breadcrumbs);

  .breadcrumb {
    padding: 20px;
    position: relative;

    &:not(.active)::after {
      content: "›";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate3d(50%, -55%, 0);
    }
  }
}

.document-links-group {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  row-gap: 40px;
}

.document-links-group__link {
  border: 1px solid var(--color-border);
  box-shadow: 8px 8px #00000033;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  position: relative;
  row-gap: 20px;
  text-decoration: none;

  &:hover .document-links-group__link__title {
    text-decoration-color: var(--color-text-decoration-highlight);
  }
}

.document-links-group__link__description {
  text-decoration: none;
}

.document-links-group__link__title {
  color: inherit;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: var(--color-link-decoration);
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 4px;
}

.primary-content-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.primary-content {
  align-items: center;
  padding: 80px 0;
  width: 100%;

  > h1,
  > h2,
  > h3,
  > iframe,
  > ol,
  > p,
  > pre,
  > ul {
    max-width: 1024px;
    width: 100%;
  }

  > h1,
  > h2,
  > h3,
  > iframe,
  > ol,
  > p,
  > ul {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.primary-navigation {
  background-color: var(--color-primary-navigation-background);

  a {
    display: flex;
    padding: 20px;
  }

  a:first-of-type {
    flex-grow: 1;
  }
}

.primary-footer {
  align-items: center;
  background-color: var(--color-primary-footer-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  row-gap: 20px;
}

.primary-footer__copyright,
.primary-footer__link-groups {
  max-width: 1600px;
  width: 100%;
}

.primary-footer__copyright {
  text-align: center;
}

.turbo-progress-bar {
  height: 8px;
  background-color: var(--color-link-active);
  position: fixed;
  top: 0;
  width: 100%;
}
